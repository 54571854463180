exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-404-page-tsx": () => import("./../../../src/templates/404-page.tsx" /* webpackChunkName: "component---src-templates-404-page-tsx" */),
  "component---src-templates-business-front-page-tsx": () => import("./../../../src/templates/business-front-page.tsx" /* webpackChunkName: "component---src-templates-business-front-page-tsx" */),
  "component---src-templates-contact-business-page-tsx": () => import("./../../../src/templates/contact-business-page.tsx" /* webpackChunkName: "component---src-templates-contact-business-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-corporate-gov-page-tsx": () => import("./../../../src/templates/corporate-gov-page.tsx" /* webpackChunkName: "component---src-templates-corporate-gov-page-tsx" */),
  "component---src-templates-employee-page-tsx": () => import("./../../../src/templates/employee-page.tsx" /* webpackChunkName: "component---src-templates-employee-page-tsx" */),
  "component---src-templates-faq-category-group-tsx": () => import("./../../../src/templates/faq-category-group.tsx" /* webpackChunkName: "component---src-templates-faq-category-group-tsx" */),
  "component---src-templates-faq-category-tsx": () => import("./../../../src/templates/faq-category.tsx" /* webpackChunkName: "component---src-templates-faq-category-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-kongleriket-page-tsx": () => import("./../../../src/templates/kongleriket-page.tsx" /* webpackChunkName: "component---src-templates-kongleriket-page-tsx" */),
  "component---src-templates-meirennbank-article-page-tsx": () => import("./../../../src/templates/meirennbank-article-page.tsx" /* webpackChunkName: "component---src-templates-meirennbank-article-page-tsx" */),
  "component---src-templates-meirennbank-page-tsx": () => import("./../../../src/templates/meirennbank-page.tsx" /* webpackChunkName: "component---src-templates-meirennbank-page-tsx" */),
  "component---src-templates-open-login-page-business-tsx": () => import("./../../../src/templates/open-login-page-business.tsx" /* webpackChunkName: "component---src-templates-open-login-page-business-tsx" */),
  "component---src-templates-open-login-page-tsx": () => import("./../../../src/templates/open-login-page.tsx" /* webpackChunkName: "component---src-templates-open-login-page-tsx" */),
  "component---src-templates-openbanking-statistics-page-tsx": () => import("./../../../src/templates/openbanking-statistics-page.tsx" /* webpackChunkName: "component---src-templates-openbanking-statistics-page-tsx" */),
  "component---src-templates-our-employees-business-page-tsx": () => import("./../../../src/templates/our-employees-business-page.tsx" /* webpackChunkName: "component---src-templates-our-employees-business-page-tsx" */),
  "component---src-templates-our-employees-page-tsx": () => import("./../../../src/templates/our-employees-page.tsx" /* webpackChunkName: "component---src-templates-our-employees-page-tsx" */),
  "component---src-templates-our-offices-business-page-tsx": () => import("./../../../src/templates/our-offices-business-page.tsx" /* webpackChunkName: "component---src-templates-our-offices-business-page-tsx" */),
  "component---src-templates-our-offices-page-tsx": () => import("./../../../src/templates/our-offices-page.tsx" /* webpackChunkName: "component---src-templates-our-offices-page-tsx" */),
  "component---src-templates-piece-section-page-tsx": () => import("./../../../src/templates/piece-section-page.tsx" /* webpackChunkName: "component---src-templates-piece-section-page-tsx" */),
  "component---src-templates-profile-manual-page-tsx": () => import("./../../../src/templates/profile-manual-page.tsx" /* webpackChunkName: "component---src-templates-profile-manual-page-tsx" */),
  "component---src-templates-search-page-tsx": () => import("./../../../src/templates/search-page.tsx" /* webpackChunkName: "component---src-templates-search-page-tsx" */)
}

